import React, { Component } from "react";


// 컨텐츠 클릭하면 선택됨
class TOC extends Component{
  render(){
    var list = [];
    var i = 0;
    while(i<this.props.data.length){
      var data = this.props.data[i];
      list.push(
        <li key={data.id}>
          <a href={data.id+'.html'} onClick={function(id, ev){
            ev.preventDefault();
            this.props.onSelect(id);
          }.bind(this, data.id)}>
            {data.title}
          </a>
        </li>
      );
        i = i + 1;
    }
    return(
      <nav>
        <ol>
          {list}
        </ol>
      </nav>
    );
  }
}


// 컨텐츠 표시
class Content extends Component{
  render(){
    return(
      <article>
        <h2>{this.props.data.title}</h2>
          {this.props.data.desc}
      </article>
    );
  }
}

// property => props
// 내용 나열 및 클릭 효과 (title, sub)
class Subject extends Component {
  render(){
    return (
      <header>
        <h1><a href="/" onClick={function(ev){
            ev.preventDefault();
            this.props.onClick();
        }.bind(this)}>{this.props.title}</a></h1>
        {this.props.sub}
      </header>
    );
  }
}

// 제출 버튼 클릭 시 title, description 에 적힌 내용으로 컨텐츠 생성
class ContentCreate extends Component{
  
  state = {
    title:'',
    desc:''
  }
  changeFormHandler(ev){
    this.setState({[ev.target.name]:ev.target.value});
  }
  render(){
    return(
      <article>
        <form onSubmit={function(ev){
          ev.preventDefault();

          this.props.onSubmit(this.state);
        }.bind(this)}>
          <p><input type="text" placeholder="title" name="title" value={this.state.title} onChange={this.changeFormHandler.bind(this)}></input></p>
          <p><textarea placeholder="description" name="desc" value={this.state.desc} onChange={this.changeFormHandler.bind(this)}></textarea></p>
          <p><input type="submit"></input></p>
        </form>
      </article>
    );
  }
}


// 메인 앱
class App extends Component {
  // 마지막 컨텐츠 id
  last_content_id = 3;
  // 기본 구성 세팅
  state = {
    mode:'read',
    selected_content_id:3,
    contents:[
      {id:1, title:'HTML', desc:'HTML is for information'},
      {id:2, title:'CSS', desc:'CSS is for design'},
      {id:3, title:'JavaScript', desc:'JavaScript is for information'},
    ]
  }

  // 선택된 컨텐츠 
  getSelectedContent(){
    var i = 0;
    while(i < this.state.contents.length){
      var data = this.state.contents[i];
      if(this.state.selected_content_id === data.id){
        return data;
      }
      i = i + 1;
    }
  }

  // 컨텐츠 변경에 따른 적용 메소드
  getContentComponent(){
    if(this.state.mode === 'read'){
      return <Content data={this.getSelectedContent()}></Content>
    }else if(this.state.mode === 'welcome'){
      return <Content data={{
        title:'Welcome',
        desc:'Hello, React!!!~~~~~~~'
      }}></Content>
    }else if(this.state.mode === 'create'){
      return <ContentCreate onSubmit={function(formData){
        console.log(formData);
        this.last_content_id = this.last_content_id + 1;
        formData.id = this.last_content_id;
        var newContents = Object.assign([], this.state.contents);
        newContents.push(formData);
        this.setState({
          contents:newContents,
          selected_content_id:this.last_content_id,
          mode:'read'
        });
      }.bind(this)}></ContentCreate>
    }
  }

  // CRUD 컨트롤러 제어
  getControlComponent(){
    return[
      <a key="1" href="/create" onClick={function(ev){
        ev.preventDefault();
        this.setState({mode: 'create'})
      }.bind(this)}>create</a>,
      <a key="2" href="/update" onClick={function(ev){
        ev.preventDefault();
      }.bind(this)}>update</a>,
      <input key="3" type="button" href="/delete" onClick={function(){
        var newContents = this.state.contents.filter(function(el){
          if(el.id !== this.state.selected_content_id){
            return el;
          }
        }.bind(this));
        this.setState({
          contents:newContents,
          mode:'welcome'
        })
      }.bind(this)} value="delete"></input>
    ];
  }

  // 랜더링
  render() {
    var content = this.getSelectedContent();
    console.log(content);
    return(
    <div className="App">
      <Subject onClick={function(){
        this.setState({mode:'welcome'});
      }.bind(this)} title="WEB" sub="World Wide Web"></Subject>
      <TOC onSelect={function(id){
        this.setState({
          selected_content_id:id,
          mode:'read'
        });
      }.bind(this)} data={this.state.contents}></TOC>
      {this.getControlComponent()}
      {this.getContentComponent()}
    </div>
    );
  }
}


// function App() {
//   return (
//     <div className="App">

//       <article>
//         <h3>HELLO</h3>
//       </article>
      
//       <nav>
//         <Food favorite="kimchi" />
//         <Food favorite="ramen" />
//         <Food favorite="samgiopsal" />
//         <Food favorite="chukumi" />
//       </nav>
      
      
//     </div>
//   );
// }

export default App;
